
import { onMounted, ref, watch } from 'vue';
import { mediaTempoChecklist } from '@/services/ChartStellantis'
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';
import { ChecklistMedia } from '@/models/ChartStellantis';
import useAlert from "@/composables/Alert";

export default {
    name: 'ChartMediaChecklist',
    components: {
        ContentLoader
    },
    props: {
        codConcessionaria: {
            type: Number,
            required: true
        },
        dataInicial: {
            type: String,
            required: true
        },
        dataFinal: {
            type: String,
            required: true
        }  
    },
    setup(props) {
        const chartInstance = ref();
        const series = ref()
        const labels = ref()
        const chartcolors = ref<string[] | []>([])
        const gridColors = ref<string[] | []>([])
        const strokeColors = ref<string[] | []>([])
        const loading = ref(true)
        const noData = ref(false)
        const responseMediaChecklist = ref<ChecklistMedia[] | []>([])
        const mediaTotalValor = ref(0)
        const { showTimeAlert } = useAlert();

        function geraOptions(labelsRef: string[] = [], refColors: string[], gridColors: string[], strokeColors: string[]) {
            const newOptions = {
                series: [{
                    name: 'Servings',
                    data: series.value
                }],
                chart: {
                    height: 250,
                    width: '100%',
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        columnWidth: '15%',
                        endingShape: 'rounded'
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                        if (dataPointIndex >= 0 && dataPointIndex < responseMediaChecklist.value.length) {
                            const currentData = responseMediaChecklist.value[dataPointIndex]
                            return `
                            <div class="card shadow-sm">
                                <div class="fs-7 p-3 apexcharts-tooltip-title"
                                    style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold; color: #3F4254; background: #F5F8FA !important;">
                                    ${currentData.nome}
                                </div>
                                <div class="p-3">
                                    <div class="fs-7">Tempo médio: <span style="font-weight: 600">${currentData.totalTempoMedio} mim</span></div>                                                                      
                                    <div class="fs-7">Tempo médio por checklist: <span style="font-weight: 600">${currentData.mediaTempoMedioPorChecklist} mim</span></div>                                                                      
                                    <div class="fs-7">Total Checklist realizado: <span style="font-weight: 600">${currentData.totalChecklistRealizado} </span></div>                                                                      
                                </div>
                            </div>`
                        } else {
                            return ''
                        }
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    width: 2,
                    colors: strokeColors
                },
                grid: {
                    row: {
                        colors: gridColors
                    }
                },
                xaxis: {
                    labels: {
                        style: {
                            fontSize: '9px'
                        }
                    },
                    categories: labels.value,
                    tickPlacement: 'on'
                },
                yaxis: {
                    show: true
                },
                fill: {
                    colors: refColors,
                },
            }

            return newOptions;
        }

        const getMediaTempoChecklist = async (codConcesionaria: string | Number, dataInicial: string, dataFinal: string): Promise<void> => {
            loading.value = true
            noData.value = false

            const payload = {
                dataInicio: dataInicial,
                dataFinal: dataFinal
            }

            try {
                const { colors, mediaTotal, mediaPorChecklist } = await mediaTempoChecklist(codConcesionaria, payload)

                responseMediaChecklist.value = mediaPorChecklist
                mediaTotalValor.value = mediaTotal
                chartcolors.value = colors.bar
                gridColors.value = colors.gridRow
                strokeColors.value = colors.stroke

                if (responseMediaChecklist.value.length > 0) {
                    series.value = mediaPorChecklist.map(checkilist => checkilist.totalTempoMedio)
                    labels.value = mediaPorChecklist.map(checkilist => {
                        const nome = checkilist.nome;

                        if (typeof nome === "string") {
                            const indexPenultimoEspaco = nome.lastIndexOf(" ", nome.lastIndexOf(" ") - 1);
                            let parteAntes, parteDepois;

                            if (indexPenultimoEspaco !== -1) {
                                parteAntes = nome.substring(0, indexPenultimoEspaco);
                                parteDepois = nome.substring(indexPenultimoEspaco + 1);
                            } else {
                                parteAntes = nome;
                                parteDepois = "";
                            }

                            const array = [parteAntes, parteDepois];

                            return array
                        } else {

                            return nome
                        }

                    })
                } else {
                    noData.value = true
                    mediaTotalValor.value = 0
                }
            } catch (error) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
                noData.value = true
            } finally {
                loading.value = false
            }

            setTimeout(() => {
                chartInstance.value.updateSeries(series.value);
                chartInstance.value.updateOptions(geraOptions(labels.value, chartcolors.value, gridColors.value, strokeColors.value));
            }, 500)
        }

        watch(() => [props.codConcessionaria, props.dataFinal, props.dataInicial], () => {
            if (props.codConcessionaria) {
                getMediaTempoChecklist(props.codConcessionaria, props.dataInicial, props.dataFinal)
            }
        }, { deep: true })

        onMounted(async () => {

            const options = geraOptions([], chartcolors.value, gridColors.value, strokeColors.value);
            chartInstance.value = new ApexChart(document.querySelector("#chart-media-tempo-checklist"), {
                ...options,
                series: [],
            });

            chartInstance.value.render();
        })

        return {
            loading,
            noData,
            mediaTotalValor
        }
    }
}
