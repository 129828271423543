import { GetChecklist } from "@/models/HistoricoChecklistStellantisModel";
import ApiService from "./ApiService";

const getHistoricoChecklistStellantis = async (codConcessionaria , postData : {
     codConsultor?: string,
     chassi?: string,
     setor?: string[],
     data?: string
}) : Promise<GetChecklist> =>  {
    const { data } = await ApiService.post(`historicochecklist/stellantis/${codConcessionaria}`, postData);
    return data;
};

const getHistoricoChecklistStellantisPaginate = async (link,payload) : Promise<GetChecklist>  => {
    const { data } = await ApiService.post(link , payload);
    return data;
 };

export {
    getHistoricoChecklistStellantis,
    getHistoricoChecklistStellantisPaginate
}
