
import { onMounted, reactive, ref, watch } from "vue";
import { ContentLoader } from 'vue-content-loader';
import { searchChassi } from "@/services/HistoricoChecklistService";
import { Checklist } from "@/models/HistoricoChecklist";
import AuthService from "@/services/AuthService";
import Datatable from "@/components/datatable/Datatable.vue";
import useEmitter from '@/composables/Emmiter';
import VerMaisModal from "./VerMaisModal.vue";
import { hideModal } from '@/core/helpers/dom';
import moment from "moment";

interface ChecklistTratado extends Checklist {
    dataTratada: string,
    horaTratada: string
}

export default {
    name: 'Modal-chassi',
    components: {
        Datatable,
        ContentLoader,
        VerMaisModal
    },
    emits: ["selected"],
    props: {
        chassi: {
            type: String,
            required: true
        },
        codConcessionaria: {
            type: Number,
            default: null
        }
    },
    setup(prop, { emit }) {
        const table = ref<ChecklistTratado[]>([])
        const userInfo = AuthService.getUsuarioAuth();
        const codNivel = userInfo.codNivel;
        const lastSelectedPage = ref(0);
        const loadingTable = ref(true)
        const emitter = useEmitter()
        const codConcessionaria = ref()
        const postData = ref()       
        const checklistSelect = ref()
        const messageModel = ref('')
        const phone = ref()
        const headers = ref([
            {
                name: "Placa",
                key: "placa",
                sortable: false,
            },
            {
                name: "Cliente",
                key: "cliente",
                sortable: false,
            },
            {
                name: "Veículo",
                key: "modelo",
                sortable: false,
            },
            {
                name: "Nº OS",
                key: "os",
                sortable: false,
            },
            {
                name: "Setor",
                key: "setor",
                sortable: false,
            },
            {
                name: "Data e Hora",
                key: "datahora",
                sortable: false,
            },
            {
                name: "Ações",
                key: "invoice",
                sortable: false,
            },
        ]);
        const itemSelected = ref({})
        const chassiSelected = ref('')
        const refModal = ref<HTMLElement | null>(null)

        onMounted(() => {
            refModal.value = document.getElementById(`modal-chassis`);
        })

        emitter.on("filtrar-historico-checklist", (filtroInfo) => {
            codConcessionaria.value = filtroInfo.codConcessionaria;
            postData.value = filtroInfo.postData;
            listaHistoricoChecklist(0);
        });

        emitter.on('update-historico-checklist' , () => {
            const { chassi, codConcessionaria } = prop
            if (codConcessionaria) {
                getChecklist(codConcessionaria)
            }
        })

        const handleChecklist = (checklist) => {
            itemSelected.value = checklist
        }

        watch(() => prop.chassi, async (newChassi) => {
            chassiSelected.value = newChassi
            const { chassi, codConcessionaria } = prop
            if (codConcessionaria) {
                getChecklist(codConcessionaria)
            }
        })

        async function listaHistoricoChecklist(page = lastSelectedPage.value) {
            lastSelectedPage.value = page;
        };

        const getChecklist = async (codConcessionaria : number) => {
            try {
                loadingTable.value = true
                const response = await searchChassi(codConcessionaria, { chassi: chassiSelected.value })

                table.value = response.map(checklist => {
                    return {
                        ...checklist,
                        dataTratada: moment(checklist.e_data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
                        horaTratada: moment(checklist.e_data, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"),
                    }
                });

                loadingTable.value = false
            } catch (error) {                
                 if(refModal.value){
                     hideModal(refModal.value);
                 }
            }
            
        }

        return {
            listaHistoricoChecklist,
            headers,
            table,
            codNivel,
            loadingTable,
            checklistSelect,
            phone,
            messageModel,
            handleChecklist,
            itemSelected
        }
    }
}
