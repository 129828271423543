
import { onMounted, ref, watch } from 'vue';
import { jeppAdventureIntelligence} from '@/services/ChartStellantis'
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';
import useAlert from "@/composables/Alert";

export default {
    name: 'ChartJeppAdventureIntelligence.',
    components: {
        ContentLoader
    },
    props: {
        codConcessionaria: {
            type: [Number],           
            required: false
        },
        dataInicial: {
            type: String,
            required: true
        },
        dataFinal: {
            type: String,
            required: true
        }        
    },
    setup(props) {
        const chartInstance = ref();
        const series = ref()
        const labels = ref()
        const colorsChart = ref<string[] | []>([])
        const loading = ref(true)
        const noData = ref(false)
        const { showTimeAlert } = useAlert();

        function geraOptions(labelsRef : string[] = [], refColors : string[]) {
            const newOptions = {
                dataLabels: {
                    enabled: true,
                },
                chart: {
                    width: '100%',
                    height: 200,
                    type: 'donut',
                    options: "chart",
                    offsetX: -15,
                    series: [],
                },
                stroke: {
                    width: 0
                },                
                labels: labelsRef,
                colors:  refColors.length > 0 ? refColors : undefined,
                fill: {
                    colors:  refColors.length > 0 ? refColors : undefined,
                },
            }

            return newOptions;
        }

        const getCadastroServicoConcectado = async (codConcesionaria: string | Number, dataInicial: string, dataFinal: string) : Promise<void> => {
            loading.value = true
            noData.value = false

            const payload = {
                dataInicio: dataInicial,
                dataFinal: dataFinal
            }
            
            try {
                const { ativado,naoObrigatorio ,naoAtivado ,colors,label} = await jeppAdventureIntelligence(codConcesionaria,payload)

                noData.value = [ativado,naoAtivado,naoObrigatorio].every((checklist) => checklist === 0)
                series.value = [ativado,naoAtivado,naoObrigatorio]
                labels.value = label
                colorsChart.value = colors

                setTimeout(() => {
                    chartInstance.value.updateSeries(series.value);
                    chartInstance.value.updateOptions(geraOptions(labels.value, colorsChart.value));
                }, 500)
                    
            } catch (error) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
            }finally{
                loading.value = false 
            }
            
        }

        watch(() => [props.codConcessionaria,props.dataFinal,props.dataInicial], () => {
            if(props.codConcessionaria){
                getCadastroServicoConcectado(props.codConcessionaria, props.dataInicial, props.dataFinal)
            }
        },{deep: true})

        onMounted(async () => {

            const options = geraOptions([], colorsChart.value);
            chartInstance.value = new ApexChart(document.querySelector("#chart-jepp-adventure-intelligence"), {
                ...options,
                series: [],
            });

            chartInstance.value.render();
        })

        return {
            loading,
            noData
        }
    }
}
