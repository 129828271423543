
import { onMounted, ref, watch } from 'vue';
import { conformidadeChecklist} from '@/services/ChartStellantis'
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';
import useAlert from "@/composables/Alert";

export default {
    name: 'ChartConformidadeChecklist',
    components: {
        ContentLoader
    },
    props: {
        codConcessionaria: {
            type: Number,
            required: true
        },
        dataInicial: {
            type: String,
            required: true
        },
        dataFinal: {
            type: String,
            required: true
        }       
    },
    setup(props) {
        const chartInstance = ref();
        const series = ref<number[]>([])
        const labels = ref<string[]>([])
        const colorsChart = ref<string[] | []>([])
        const loading = ref(true)
        const noData = ref(false)
        const { showTimeAlert } = useAlert();

        function geraOptions(labelsRef : string[] = [], refColors : string[]) {
            const newOptions = {
                dataLabels: {
                    enabled: true,
                },
                chart: {
                    width: '100%',
                    height: 200,
                    type: 'donut',
                    options: "chart",
                    offsetX: -15,
                    series: [],
                },
                stroke: {
                    width: 0
                },               
                labels: labelsRef,
                colors: refColors.length > 0 ? refColors : undefined,
                fill: {
                    colors: refColors.length > 0 ? refColors : undefined,
                },
            }

            return newOptions;
        }

        const getTipoChecklist = async (codConcesionaria: string | Number, dataInicial: string, dataFinal: string) : Promise<void> => {
            loading.value = true
            noData.value = false

            const payload = {
                dataInicio: dataInicial,
                dataFinal: dataFinal
            }

            try {
                const { comConformidade, semConformidade ,label,colors} = await conformidadeChecklist(codConcesionaria, payload)

                noData.value = comConformidade == 0 && semConformidade == 0
                series.value = [comConformidade, semConformidade]
                labels.value = label
                colorsChart.value = colors

                setTimeout(() => {
                    chartInstance.value.updateSeries(series.value);
                    chartInstance.value.updateOptions(geraOptions(labels.value, colorsChart.value));
                }, 500)

            } catch (error) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
                noData.value = true
            }finally{
                loading.value = false
            }           
        }

        watch(() => [props.codConcessionaria,props.dataFinal,props.dataInicial], () => {   
            
            if(props.codConcessionaria){
                getTipoChecklist(props.codConcessionaria,props.dataInicial,props.dataFinal)    
            }               
        })

        onMounted(async () => {
            const options = geraOptions([], colorsChart.value);
            chartInstance.value = new ApexChart(document.querySelector("#chart-conformidade-checklist"), {
                ...options,
                series: [],
            });

            chartInstance.value.render();
        })

        return {
            loading,
            noData
        }
    }
}
